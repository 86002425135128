import { StationMixer } from '@linktivity/sagano';
export const ADMIN_HOST = import.meta.env.VITE_APP_ADMIN_HOST;
export const MAX_LUAGGAGE = 99;
export const MIN_LUAGGAGE = 1;
export const LUGGAGE_TYPE = '21';
export const SAGA = '1'; // 嵯峨
export const HOZUKYO = '3'; // 保津峡
export const KAMEOKA = '4'; // 亀岡
export const ADULT_NORMAL = '1'; // 大人NORMAL
export const BOOKING_NORMAL = 'ORDER_TYPE_NORMAL'; //普通预约
export const BOOKING_QUANTITY_MAX = 50;
export const REVISION_CREATE = '0'; //创建版本
export const STANDING_ARRANGEMENT_TYPE_ID = '7'; //立席类型
export const BOOKING_UPDATE_MODE = {
  // 变更订单模式区分
  Service: 'SERVICE',
  Participant: 'PARTICIPANT',
  Unit: 'UNIT'
};
export const PAYMENT_MODE = {
  Create: 'CREATE',
  Cancel: 'CANCEL'
};
export const ReportTypes = {
  Booking: 'booking',
  Train: 'train',
  Seat: 'seat',
  Suspended: 'suspended'
};
export const UNKNOWN = 'unknown';
export const HANDICAPPED_1 = ['5', '6']; // 障害者第1種
export const SUPPORTER = ['13', '14']; // 介護者

const BookingChannel = StationMixer.BookingChannel;
type BookingChannel = StationMixer.BookingChannel;

const PrintStatus = StationMixer.PrintStatus;
type PrintStatus = StationMixer.PrintStatus;

const BookingType = StationMixer.InstantbookingmanBookingType;
type BookingType = StationMixer.InstantbookingmanBookingType;

const BookingStatus = StationMixer.BookingStatus;
type BookingStatus = StationMixer.BookingStatus;

const PaymentStatus = StationMixer.PaymentStatus;
type PaymentStatus = StationMixer.PaymentStatus;

const OrderType = StationMixer.OrderType;
type OrderType = StationMixer.OrderType;
export const BookingParamsType = StationMixer.BookingParamsType;

const ServiceStatus = StationMixer.SearchBookingsServiceStatusEnum;
type ServiceStatus = StationMixer.SearchBookingsServiceStatusEnum;

export {
  BookingChannel,
  PrintStatus,
  BookingType,
  BookingStatus,
  PaymentStatus,
  OrderType,
  ServiceStatus
};
